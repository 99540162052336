import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { Show, Typography } from "@pankod/refine-antd";

import { IEmployee } from "interfaces";

const { Title, Text } = Typography;

export const EmployeeShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IEmployee>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>ID</Title>
      <Text>{record?.id}</Text>
      <Title level={5}>Nombre</Title>
      <Text>{record?.name}</Text>
      <Title level={5}>Tipo</Title>
      <Text>{record?.type}</Text>
      <Title level={5}>UO/UA</Title>
      <Text>{record?.organizational_unit?.name}</Text>
    </Show>
  );
};
