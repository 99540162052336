import { IResourceComponentsProps, useOne, usePermissions } from "@pankod/refine-core";
import {
  Edit,
  Form,
  Input,
  SaveButton,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
import DatePicker from "components/atoms/datepicker.atom";
import dayjs from "dayjs";
import "react-mde/lib/styles/css/react-mde-all.css";

import { IAdministrativePosition, IEmployee, IEmployeeStatus, IOrganizationalUnit, IUnionProposal } from "interfaces";
import { useEffect, useState } from "react";
import { PermissionsConstants } from "constants/permissions";

export const UnionProposalEdit: React.FC<IResourceComponentsProps> = () => {

  const { data: permissionsData } = usePermissions();

  const { form, formProps, saveButtonProps, queryResult } = useForm<IUnionProposal>();
  const [idEmployee, setIdEmployee] = useState(0);

  const employeeQueryResult = useOne<IEmployee>({
    resource: "employees",
    id: idEmployee,
    queryOptions: {
        enabled: false,
        onSuccess: (data) => {
          form.setFieldsValue({
            phone: data?.data?.phone || '',
            id_organizational_unit: data?.data?.id_organizational_unit,
          });
        },
    },
  });

  useEffect(() => {employeeQueryResult.refetch()}, [idEmployee]);

  const { selectProps: uoSelectProps } = useSelect<IOrganizationalUnit>({
    resource: "organizational-units",
    optionLabel: "name",
    optionValue: "id",
    defaultValue: queryResult?.data?.data.id_organizational_unit || undefined,
  });

  const { selectProps: employeeSelectProps } = useSelect<IEmployee>({
    resource: "employees",
    optionLabel: "name",
    optionValue: "id",
    defaultValue: queryResult?.data?.data.id_employee || undefined, 
  });

  const { selectProps: apSelectProps } = useSelect<IAdministrativePosition>({
    resource: "administrative-positions",
    optionLabel: "name",
    optionValue: "id",
    fetchSize: 30,
    defaultValue: queryResult?.data?.data.id_administrative_position || undefined, 
  });

  const { selectProps: esSelectProps } = useSelect<IEmployeeStatus>({
    resource: "employees-status",
    optionLabel: "name",
    optionValue: "id",
    fetchSize: 30,
    defaultValue: queryResult?.data?.data?.id_replace_employee_status || undefined, 
  });

  const handleWorkerChange = async(value: any) => {
    setIdEmployee(value);
  }

  return (
    <Edit
      title="Editar Propuesta Sindical"
      footerButtons={ () => <SaveButton {...saveButtonProps}>Guardar</SaveButton> }
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Fecha"
          name="union_proposal_date"
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : "",
          })}
        >
          <DatePicker style={{width: '100%'}} format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item
          label="Empleado"
          name="id_employee"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select 
            showSearch 
            {...employeeSelectProps} 
            onChange={handleWorkerChange}
          />
        </Form.Item>
        <Form.Item
          label="Celular"
          name="phone"
          rules={[
            {
              required: true,
            },
          ]}
          shouldUpdate={(prevValues, curValues) =>
            prevValues.phone !== curValues.phone
          }
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Unidad Organizaciónal/Académica"
          name="id_organizational_unit"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select 
            showSearch
            {...uoSelectProps} 
          />
        </Form.Item>
        <Form.Item
          label="Cubrirá"
          name="union_proposal_type"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={[
              {
                label: "Temporal",
                value: "TEMPORARY",
              },
              {
                label: "Definitivo",
                value: "DEFINITIVE",
              }
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Plaza"
          name="id_administrative_position"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select 
            showSearch
            {...apSelectProps} 
          />
        </Form.Item>
        <Form.Item
          label="Sustitución de"
          name="id_replace_employee"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select 
            showSearch 
            {...employeeSelectProps}
          />
        </Form.Item>
        <Form.Item
          label="Quien se encuentra"
          name="id_replace_employee_status"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select 
            showSearch
            {...esSelectProps} 
          />
        </Form.Item>
        <Form.Item
          label="Quien firma?"
          name="union_proposal_signer"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={[
              {
                label: "Administrativo",
                value: "ADMINISTRATIVE",
              },
              {
                label: "Vicerrector",
                value: "VICERRECTOR",
              }
            ]}
          />
        </Form.Item>
        { permissionsData?.includes(PermissionsConstants.UNION_PROPOSAL_GOD) && (
          <Form.Item
            label="Estatus"
            name="status"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Select
              options={[
                {
                  label: "Registrada",
                  value: "REGISTERED",
                },
                {
                  label: "En transito",
                  value: "TRANSIT",
                },
                {
                  label: "Aceptada",
                  value: "ACCEPTED",
                },
                {
                  label: "Rechazada",
                  value: "REFUSED",
                },
                {
                  label: "Enviada",
                  value: "SENT",
                },
                {
                  label: "Movimiento Realizado",
                  value: "FINISHED",
                }
              ]}
            />
          </Form.Item>
        )}
      </Form>
    </Edit>
  );
};
