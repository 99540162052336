import { AccessControlProvider } from "@pankod/refine-core";

export const accessControlProvider = (permissions: string = ''): AccessControlProvider => {
  return {
    can: async({ resource, action, params }) => {
      //console.log(permissions, resource, action, params);

      const searchPermission = (p: string) => permissions?.includes(p+':index');
      let can = searchPermission(resource);
      
      switch(resource) {
        case 'Dashboard':
        case 'dashboard':
          can = true;
      }

      return Promise.resolve({ can });
    },
  };
};
