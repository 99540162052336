import { TreeDataNode } from "antd";

export const permissionsDataNode: TreeDataNode[] = [
    {
        title: 'Envios a culiacan',
        key: 'shipments:index',
    },
    {
        title: 'Asuntos Laborales y Correspondencia',
        key: 'correspondence:menu',
        children: [
            {
                title: 'Normal',
                key: 'correspondence:index',
            },
            {
                title: 'Admin',
                key: 'correspondence:god',
            },
        ]
    },
    {
        title: 'Propuestas sindicales',
        key: 'union-proposals:menu',
        children: [
            {
                title: 'Normal',
                key: 'union-proposals:index',
            },
            {
                title: 'Admin',
                key: 'union-proposals:god',
            },
        ]
    },
    {
        title: 'Incapacidades',
        key: 'disabilities:menu',
        children: [
            {
                title: 'Normal',
                key: 'disabilities:index',
            },
            {
                title: 'Admin',
                key: 'disabilities:god',
            },
        ]
    },
    /*{
        title: 'Custom',
        key: 'custom:menu',
        children: [
        {
            title: 'Demo1',
            key: 'custom:demo1'
        }
        ]
    },*/
    {
        title: 'Configuración',
        key: 'management:menu',
        children: [
            {
                title: 'Empleados',
                key: 'employees:menu',
                children: [
                    {
                        title: 'Normal',
                        key: 'employees:index',
                    },
                    {
                        title: 'Admin',
                        key: 'employees:god',
                    },
                ]
            },
            {
                title: 'Escuelas',
                key: 'organizational-units:index'
            },
            {
                title: 'Plazas',
                key: 'administrative-positions:index'
            },
            {
                title: 'Estatus de empleados',
                key: 'employees-status:index'
            },
            {
                title: 'Usuarios',
                key: 'users:index'
            }
        ]
    }
];

export const PermissionsConstants = {
    SHIPMENTS_INDEX: 'shipments:index',
    CORRESPONDENCE_INDEX: 'correspondence:index',
    CORRESPONDENCE_GOD: 'correspondence:god',
    UNION_PROPOSAL_INDEX: 'union-proposals:index',
    UNION_PROPOSAL_GOD: 'union-proposals:god',
    DISABILITIES_INDEX: 'disabilities:index',
    DISABILITIES_GOD: 'disabilities:god',
    EMPLOYEES_INDEX: 'employees:index',
    EMPLOYEES_GOD: 'employees:god',
}