import { IResourceComponentsProps, useShow, useTitle } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
  DateField,
  Tag,
  CreateButton,
  Modal,
  Show,
  Typography,
} from "@pankod/refine-antd";
import { IShipment } from "interfaces";
import { useState } from "react";

const getTagValue = (value: string = '') => {
  switch(value) {
    case 'RECEIVED': return 'Recibido';
    case 'SEND': return 'Enviado';
    default: return value;
  }
}

const getTagColor = (value: string = '') => {
  if(value === 'RECEIVED' ) {
    return 'green';
  }
  return 'blue';
}

const { Title, Text } = Typography;

export const ShipmentsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<IShipment>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    initialPageSize: 15,
  });
  const [visible, setVisible] = useState(false);
  const { queryResult, showId, setShowId } = useShow<IShipment>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <>
      <List
        title="Envios a Culiacán"
        headerButtons={() => <CreateButton type="primary">Nuevo Envio</CreateButton> }
      >
        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="id"
            key="id"
            title="ID"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("id", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="shipment_date"
            key="shipment_date"
            title="Fecha de envio"
            render={(value) => <DateField value={value} format="LL" />}
            defaultSortOrder={getDefaultSortOrder("shipment_date", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="responsible"
            key="responsible"
            title="Responsable(s)"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("responsible", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="formalities"
            key="formalities"
            title="Tramites"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("formalities", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="status"
            key="status"
            title="Estatus"
            render={(value) => <Tag color={getTagColor(value)}>{getTagValue(value)}</Tag>}
            defaultSortOrder={getDefaultSortOrder("status", sorter)}
            sorter
          />
          <Table.Column<IShipment>
            title="Acciones"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id}  
                  onClick={() => {
                    setShowId(record.id);
                    setVisible(true);
                }} />
                <DeleteButton 
                  hideText
                  size="small"
                  recordItemId={record.id}
                  confirmTitle="¿Seguro que deseas eliminar este registro?"
                  confirmOkText="Borrar"
                  confirmCancelText="Cancelar"
                />
              </Space>
            )}
          />
        </Table>
      </List>
      <Modal visible={visible} onCancel={() => setVisible(false)} onOk={() => setVisible(false)} >
        <Show
          isLoading={isLoading}
          recordItemId={showId}
          headerProps={{
            title: 'Envio a Culiacan'
          }}
        >
          <Title level={5}>ID</Title>
          <Text>{record?.id}</Text>
          <Title level={5}>Fecha de envio</Title>
          <DateField value={record?.shipment_date} format="LL" />
          <Title level={5}>Responsable(s)</Title>
          <Text>{record?.responsible}</Text>
          <Title level={5}>Tramites</Title>
          <Text>{record?.formalities}</Text>
          <Title level={5}>Estatus</Title>
          <Tag color={getTagColor(record?.status)}>{getTagValue(record?.status)}</Tag>
        </Show>
      </Modal>
    </>
  );
};
