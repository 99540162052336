import { IResourceComponentsProps, usePermissions } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IEmployee, IOrganizationalUnit } from "interfaces";
import { useState } from "react";
import { PermissionsConstants } from "constants/permissions";

export const EmployeeCreate: React.FC<IResourceComponentsProps> = () => {

  const { data: permissionsData } = usePermissions();

  const { form, formProps, saveButtonProps } = useForm<IEmployee>();
  const { selectProps: uoSelectProps } = useSelect<IOrganizationalUnit>({
    resource: "organizational-units",
    optionLabel: "name",
    optionValue: "id",
  });

  const [isHiddenId, setIsHiddenId] = useState(!permissionsData?.includes(PermissionsConstants.EMPLOYEES_GOD));

  const handleWorkerTypeChange = async(value: any) => {
    if(value === 'yes') {
      setIsHiddenId(true);
      form.setFieldsValue({
        id: 90000,
      })
    } else {
      setIsHiddenId(false);
      form.setFieldsValue({
        id: '',
      })
    }
  }

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        { permissionsData?.includes(PermissionsConstants.EMPLOYEES_GOD) && (
          <Form.Item
            label="¿Nuevo Ingreso?"
            name="worker_type"
            rules={[
              {
                required: false,
              },
            ]}
            initialValue={'no'}
          >
            <Select
              options={[
                {
                  label: "Sí",
                  value: "yes",
                },
                {
                  label: "No",
                  value: "no",
                }
              ]}
              onChange={handleWorkerTypeChange}
            />
          </Form.Item>
        )}
        <Form.Item
          label="No. Empleado"
          name="id"
          rules={[
            {
              required: true,
            },
          ]}
          hidden={isHiddenId}
          initialValue={permissionsData?.includes(PermissionsConstants.EMPLOYEES_GOD) ? '' : '90000'}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Nombre"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Celular"
          name="phone"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Tipo"
          name="type"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={[
              {
                label: "Administrativo",
                value: "ADMINISTRATIVO",
              },
              {
                label: "Acádemico",
                value: "ACADÉMICO",
              },
              {
                label: "Confianza",
                value: "CONFIANZA",
              },
              {
                label: "Jubilado",
                value: "JUBILADO",
              }
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Unidad Organizaciónal/Académica"
          name="id_organizational_unit"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select 
            showSearch 
            {...uoSelectProps} 
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
