import { CrudFilters, IResourceComponentsProps, usePermissions, useShow, useUpdate } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
  Tag,
  Modal,
  Show,
  Typography,
  CreateButton,
  Dropdown,
  Menu,
  DateField,
  Button,
  useModalForm,
  Form,
  Input,
  Row,
  Col,
  Icons,
} from "@pankod/refine-antd";
import { IInability } from "interfaces";
import { useState } from "react";
import { CheckOutlined, CloseOutlined, DownOutlined } from "@ant-design/icons";
import { downloadFileFromApi } from "utils";
import DatePicker from "components/atoms/datepicker.atom";
import dayjs from "dayjs";
import { PermissionsConstants } from "constants/permissions";

const getTagValue = (value: string = '') => {
  switch(value) {
    case 'REGISTERED': return 'REGISTRADA';
    case 'SENT': return 'ENVIADA A CUL.';
    case 'FINISHED': return 'CAPTURADA EN PRESTACIONES';
    default: return value;
  }
}

const getTagColor = (value: string = '') => {
  switch(value) {
    case 'FINISHED': return 'green';
    case 'REFUSED': return 'red';
  }
  return 'blue';
}

const getTagColorExpire = (value: any) => {
  
  const date = dayjs(value);
  const today = dayjs();
  const nextFiveDays = dayjs().add(3, 'day');
  if( date < today) {
    return 'red';
  }
  if( date < nextFiveDays) {
    return '#D6E30F';
  }
  return 'green';
}

const { Title, Text } = Typography;

export const InabilityList: React.FC<IResourceComponentsProps> = () => {

  const { data: permissionsData } = usePermissions();

  const { tableProps, sorter, tableQueryResult, searchFormProps, setFilters } = useTable<IInability>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    initialPageSize: 15,
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { q } = params;
      filters.push(
          {
              field: 'search',
              operator: 'eq',
              value: q,
          },
      );
      return filters;
    },
  });
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [visible, setVisible] = useState(false);
  const { queryResult, showId, setShowId } = useShow<IInability>();

  const { mutate } = useUpdate<IInability>();

  const { data, isLoading } = queryResult;
  const record = data?.data;

  const handleDropDownF1 = async() => {
    setIsLoadingFile(true);
    const fileName = `oficio_${'1'}.pdf`;
    await downloadFileFromApi(`/disabilities/files/generate`, fileName, { id:1, file: 'inability-report', type: 'pdf' });
    setIsLoadingFile(false);
  }

  const handleNearExpire = async() => {
    setIsLoadingFile(true);
    setFilters([{
      field: 'nearExpire',
      operator: 'eq',
      value: true,
    },
    {
      field: 'expired',
      operator: 'eq',
      value: 2,
    }]);
    setIsLoadingFile(false);
  }

  const handleExpired = async() => {
    setIsLoadingFile(true);
    setFilters([{
      field: 'expired',
      operator: 'eq',
      value: true,
    },
    {
      field: 'nearExpire',
      operator: 'eq',
      value: 2,
    }]);
    setIsLoadingFile(false);
  }

  const handleResetFilters = async() => {
    setIsLoadingFile(true);
    setFilters([{
      field: 'nearExpire',
      operator: 'eq',
      value: 2,
    },
    {
      field: 'expired',
      operator: 'eq',
      value: 2,
    }]);
    setIsLoadingFile(false);
  }

  const handleDropDownMenuF1Click = async(e: any) => {
    setIsLoadingFile(true);
    const key = e.key;
    const fileName = `oficio_${'1'}.docx`;
    switch(key) {
      case '2':
        console.log("PDF");
        await downloadFileFromApi(`/disabilities/files/generate`, fileName, { id:1, file: 'inability-report', type: 'docx' });
        setIsLoadingFile(false);
        break;
    }
  };

  const DropDownMenuF1 = () => (
    <Menu
      onClick={(e) => handleDropDownMenuF1Click(e)}
      items={[
        {
          label: 'Word',
          key: '2',
        },
      ]}
    />
  );

  const handleUpdateStatus = (action:string, id: any) => {
    mutate({
      resource: 'disabilities',
      id,
      values: {
        status: action
      }
    })
  }

  const renderActionButton = (record: IInability) => {
    switch(record.status) {
      case 'REGISTERED':
        return <Button type="primary" color="blue" onClick={() => handleUpdateStatus('SENT', record.id)}>ENVIADA A CUL.</Button>;
      case 'SENT':
        return <Button type="primary" color="blue" onClick={() => handleUpdateStatus('FINISHED', record.id)}>CAPTURADA EN PRESTACIONES</Button>;
    }
  }

  return (
    <>
      <List title="Incapacidades" headerButtons={() => <CreateButton type="primary">Nueva Incapacidad</CreateButton> } >
        <Form layout="vertical" {...searchFormProps}>
          <Row gutter={[20, 0]}>
            <Col span={12}>
              <Form.Item name="q">
                  <Input
                      placeholder="Nombre o Folio"
                      prefix={<Icons.SearchOutlined />}
                  />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item>
                  <Button htmlType="submit" type="primary">
                      Buscar
                  </Button>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Dropdown.Button
                icon={<DownOutlined />}
                overlay={DropDownMenuF1()}
                type="primary"
                onClick={() => handleDropDownF1()}
                loading={isLoadingFile}
              >
                Generar Oficio
              </Dropdown.Button>
              <Button
                type="default"
                onClick={() => handleNearExpire()}
                loading={isLoadingFile}
                style={{ marginLeft: 20   }}
              >
                Próximas a vencer
              </Button>
              <Button
                type="default"
                onClick={() => handleExpired()}
                loading={isLoadingFile}
                style={{ marginLeft: 20   }}
              >
                Vencidas
              </Button>
              <Button
                type="default"
                onClick={() => handleResetFilters() }
                loading={isLoadingFile}
                style={{ marginLeft: 20   }}
              >
                Reset
              </Button>
            </Col>
          </Row>
        </Form>
        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="id"
            key="id"
            title="ID"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("id", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="id_employee"
            key="id_employee"
            title="Nombre"
            render={(value, row: IInability) => <TextField value={ row?.employee?.name } />}
            defaultSortOrder={getDefaultSortOrder("id_employee", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="id_employee"
            key="id_employee"
            title="Tipo Empleado"
            render={(value, row: IInability) => <TextField value={ row?.employee?.type } />}
            defaultSortOrder={getDefaultSortOrder("id_employee", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="phone"
            key="phone"
            title="Celular"
            render={(value, row: IInability) => <TextField value={ row?.employee?.phone } />}
            defaultSortOrder={getDefaultSortOrder("phone", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="id_organizational_unit"
            key="id_organizational_unit"
            title="UO/UA Destino"
            render={(value, row: IInability) => <TextField value={ row?.organizational_unit?.name || "---"} /> }
            defaultSortOrder={getDefaultSortOrder("id_organizational_unit", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="inability_invoice"
            key="inability_invoice"
            title="Folio"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("inability_invoice", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="inability_days"
            key="inability_days"
            title="Dias de Incapacidad"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("inability_days", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="inability_start_date"
            key="inability_start_date"
            title="A partir del:"
            render={(value) => value ? <DateField value={value} format="DD/MM/YYYY" /> : <></>}
            defaultSortOrder={getDefaultSortOrder("inability_start_date", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="inability_end_date"
            key="inability_end_date"
            title="Finaliza el:"
            render={(value) => value ? <Tag color={getTagColorExpire(value)}><DateField value={value} format="DD/MM/YYYY" /></Tag> : <></> }
            defaultSortOrder={getDefaultSortOrder("inability_end_date", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="inability_recovery_days"
            key="inability_recovery_days"
            title="Dias estimados"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("inability_recovery_days", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="covered_by"
            key="covered_by"
            title="¿Cubierto?"
            render={(value) => value ? <Tag color={'blue'}>Sí</Tag> : <Tag color={'red'}>No</Tag> }
            defaultSortOrder={getDefaultSortOrder("covered_by", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="status"
            key="status"
            title="Estatus"
            render={(value) => <Tag color={getTagColor(value)}>{getTagValue(value)}</Tag>}
            defaultSortOrder={getDefaultSortOrder("status", sorter)}
            sorter
          />
          <Table.Column<IInability>
            title="Actions"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id}
                  onClick={() => {
                    setShowId(record.id);
                    setVisible(true);
                }} />
                { (permissionsData?.includes(PermissionsConstants.DISABILITIES_GOD) || record.status === 'REGISTERED') && (
                  <DeleteButton hideText size="small" recordItemId={record.id} />
                )}
                { renderActionButton(record) }
              </Space>
            )}
          />
        </Table>
      </List>
      <Modal visible={visible} onCancel={() => setVisible(false)}>
        <Show title="Incapacidad" isLoading={isLoading} recordItemId={showId} headerButtons={<></>}>
          <Title level={5}>ID</Title>
          <Text>{record?.id}</Text>
          <Title level={5}>ID Empleado</Title>
          <Text>{record?.id_employee}</Text>
          <Title level={5}>Nombre</Title>
          <Text>{record?.employee?.name}</Text>
          <Title level={5}>Tipo de Empleado:</Title>
          <Text>{record?.employee?.type}</Text>
          <Title level={5}>UO/UA</Title>
          <Text>{record?.organizational_unit?.name}</Text>
          <Title level={5}>Folio</Title>
          <Text>{record?.inability_invoice}</Text>
          <Title level={5}>Fecha de Recepción</Title>
          <DateField value={record?.inability_date} format="DD/MM/YYYY" />
          <Title level={5}>Expedido el:</Title>
          <DateField value={record?.inability_registered_date} format="DD/MM/YYYY" />
          <Title level={5}>A partir del:</Title>
          <DateField value={record?.inability_start_date} format="DD/MM/YYYY" />
          <Title level={5}>Finaliza el:</Title>
          <DateField value={record?.inability_end_date} format="DD/MM/YYYY" />
          <Title level={5}>Dias estimados de recuperación:</Title>
          <DateField value={record?.inability_recovery_days} format="DD/MM/YYYY" />
          {
            record?.covered_employee?.name && (
              <>
                <Title level={5}>Empleado que cubre:</Title>
                <Text>{record?.covered_employee?.name}</Text>
                <Title level={5}>Tipo de Empleado:</Title>
                <Text>{record?.covered_employee?.type}</Text>
                <Title level={5}>Dias que cubre:</Title>
                <Text>{record?.covered_days}</Text>
              </>
            )
          }
          <Title level={5}>Observaciones</Title>
          <Text>{record?.observations}</Text>
          <Title level={5}>Estatus</Title>
          <Tag color={getTagColor(record?.status)}>{getTagValue(record?.status)}</Tag>
        </Show>
      </Modal>
    </>
  );
};
