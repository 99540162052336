import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
  Modal,
  Show,
  Typography,
} from "@pankod/refine-antd";
import { IAdministrativePosition } from "interfaces";
import { useState } from "react";

const { Title, Text } = Typography;

export const AdministrativePositionList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<IAdministrativePosition>({
    initialSorter: [
      {
        field: "id",
        order: "asc",
      },
    ],
    hasPagination: false,
  });
  const [visible, setVisible] = useState(false);
  const { queryResult, showId, setShowId } = useShow<IAdministrativePosition>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <>
      <List>
        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="id"
            key="id"
            title="ID"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("id", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="name"
            key="name"
            title="Nombre"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("name", sorter)}
            sorter
          />
          <Table.Column<IAdministrativePosition>
            title="Actions"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id}  
                  onClick={() => {
                    setShowId(record.id);
                    setVisible(true);
                }} />
                <DeleteButton hideText size="small" recordItemId={record.id} />
              </Space>
            )}
          />
        </Table>
      </List>
      <Modal visible={visible} onCancel={() => setVisible(false)}>
        <Show isLoading={isLoading} recordItemId={showId}>
          <Title level={5}>ID</Title>
          <Text>{record?.id}</Text>
          <Title level={5}>Nombre</Title>
          <Text>{record?.name}</Text>
        </Show>
      </Modal>
    </>
  );
};
