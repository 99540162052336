import React from "react";
import ReactDOM from "react-dom";

import reportWebVitals from "./reportWebVitals";
import AppRh from "./AppRh";
import AppVice from "./AppVice";

const hostname = window.location.hostname;

console.log(hostname);

const multitenant = hostname === 'vice.royercross.com' ? <AppVice /> : <AppRh />;

ReactDOM.render(
  <React.StrictMode>
    { multitenant }
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
