import { IResourceComponentsProps, useDataProvider, useOne } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  SaveButton,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
import "react-mde/lib/styles/css/react-mde-all.css";

import { ICorrespondence, IEmployee, IOrganizationalUnit } from "interfaces";
import { useEffect, useState } from "react";

export const CorrespondenceCreate: React.FC<IResourceComponentsProps> = () => {

  const { form, formProps, saveButtonProps } = useForm<ICorrespondence>();
  const [isExternal, setIsExternal] = useState(false);
  const [idEmployee, setIdEmployee] = useState(0);

  const employeeQueryResult = useOne<IEmployee>({
    resource: "employees",
    id: idEmployee,
    queryOptions: {
        enabled: false,
        onSuccess: (data) => {
          form.setFieldsValue({
            phone: data?.data?.phone || '',
            id_organizational_unit: data?.data?.id_organizational_unit,
          });
        },
    },
  });

  useEffect(() => {employeeQueryResult.refetch()}, [idEmployee]);

  const { selectProps: uoSelectProps } = useSelect<IOrganizationalUnit>({
    resource: "organizational-units",
    optionLabel: "name",
    optionValue: "id",
    defaultValue: employeeQueryResult?.data?.data.id_organizational_unit || undefined,
  });

  const { selectProps: employeeSelectProps } = useSelect<IEmployee>({
    resource: "employees",
    optionLabel: "name",
    optionValue: "id",
  });

  const handleWorkerTypeChange = (value: any) => {
    setIsExternal( value === 'yes' ? true : false);
  }

  const handleWorkerChange = async(value: any) => {
    setIdEmployee(value);
  }

  return (
    <Create 
      title="Crear Asunto Laboral y Correspondencia"
      footerButtons={ () => <SaveButton {...saveButtonProps}>Guardar</SaveButton> }
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
            label="¿Trabajador Externo?"
            name="worker_type"
            rules={[
              {
                required: true,
              },
            ]}
            initialValue={'no'}
          >
            <Select
              options={[
                {
                  label: "Sí",
                  value: "yes",
                },
                {
                  label: "No",
                  value: "no",
                }
              ]}
              onChange={handleWorkerTypeChange}
            />
          </Form.Item>
          { isExternal ? (
            <>
              <Form.Item
                label="Nombre Completo"
                name="name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Celular"
                name="phone"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                label="Empleado"
                name="id_employee"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select 
                  showSearch 
                  {...employeeSelectProps} 
                  onChange={handleWorkerChange}
                />
              </Form.Item>
              <Form.Item
                label="Celular"
                name="phone"
                rules={[
                  {
                    required: true,
                  },
                ]}
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.phone !== curValues.phone
                }
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Asunto"
                name="subject"
                rules={[
                  {
                    required: true,
                  },
                ]}
                initialValue={'WORK'}
              >
                <Select
                  options={[
                    {
                      label: "Personal",
                      value: "PERSONAL",
                    },
                    {
                      label: "Laboral",
                      value: "WORK",
                    }
                  ]}
                />
              </Form.Item>
              <Form.Item
                label="Unidad Organizaciónal/Académica"
                name="id_organizational_unit"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select 
                  showSearch
                  {...uoSelectProps} 
                />
              </Form.Item>
            </>
          )}
      </Form>
    </Create>
  );
};
