import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Edit,
  EditButton,
  Form,
  Input,
  SaveButton,
  Select,
  useForm,
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IShipment } from "interfaces";
import DatePicker from "components/atoms/datepicker.atom";
import dayjs from "dayjs";

export const ShipmentEdit: React.FC<IResourceComponentsProps> = () => {

  const { formProps, saveButtonProps } = useForm<IShipment>();

  return (
    <Edit
      title="Editar Envio"
      footerButtons={ () => <SaveButton {...saveButtonProps}>Guardar</SaveButton> }
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Fecha de envio"
          name="shipment_date"
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : "",
        })}
        >
          <DatePicker style={{width: '100%'}} />
        </Form.Item>
        <Form.Item
          label="Responsable (s)"
          name="responsible"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Tramites"
          name="formalities"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea rows={5} />
        </Form.Item>
        <Form.Item
          label="Estatus"
          name="status"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Select
            options={[
              {
                label: "Enviado",
                value: "SEND",
              },
              {
                label: "Recibido",
                value: "RECEIVED",
              }
            ]}
          />
        </Form.Item>
      </Form>
    </Edit>
  );
};
