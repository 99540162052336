import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  SaveButton,
  useForm,
} from "@pankod/refine-antd";
import "react-mde/lib/styles/css/react-mde-all.css";

import { IShipment } from "interfaces";
import DatePicker from "components/atoms/datepicker.atom";
import dayjs from "dayjs";

export const ShipmentCreate: React.FC<IResourceComponentsProps> = () => {

  const { formProps, saveButtonProps } = useForm<IShipment>();

  return (
    <Create 
      title="Registrar envio a Culiacán"
      footerButtons={ () => <SaveButton {...saveButtonProps}>Guardar</SaveButton> }
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Fecha de envio"
          name="shipment_date"
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={dayjs()}
        >
          <DatePicker style={{width: '100%'}} format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item
          label="Responsable (s)"
          name="responsible"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Tramites"
          name="formalities"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea rows={5} />
        </Form.Item>
      </Form>
    </Create>
  );
};
