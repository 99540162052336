import { IResourceComponentsProps, useOne, usePermissions } from "@pankod/refine-core";
import {
  Edit,
  Form,
  Input,
  SaveButton,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
import DatePicker from "components/atoms/datepicker.atom";
import dayjs from "dayjs";
import "react-mde/lib/styles/css/react-mde-all.css";

import { IAdministrativePosition, IEmployee, IEmployeeStatus, IOrganizationalUnit, IInability } from "interfaces";
import { useEffect, useState } from "react";

export const InabilityEdit: React.FC<IResourceComponentsProps> = () => {

  const { data: permissionsData } = usePermissions();
  const [isHiddenCovered, setIsHiddenCovered] = useState(true);

  const { form, formProps, saveButtonProps, queryResult } = useForm<IInability>({
    queryOptions: {
      onSuccess: (data) => {
        setIsHiddenCovered((data?.data.covered_by ? false : true));
      }
    }
  });
  const [idEmployee, setIdEmployee] = useState(0);

  const employeeQueryResult = useOne<IEmployee>({
    resource: "employees",
    id: idEmployee,
    queryOptions: {
        enabled: false,
        onSuccess: (data) => {
          form.setFieldsValue({
            phone: data?.data?.phone || '',
            id_organizational_unit: data?.data?.id_organizational_unit,
          });
        },
    },
  });

  useEffect(() => {employeeQueryResult.refetch()}, [idEmployee]);

  const { selectProps: uoSelectProps } = useSelect<IOrganizationalUnit>({
    resource: "organizational-units",
    optionLabel: "name",
    optionValue: "id",
    defaultValue: queryResult?.data?.data.id_organizational_unit || undefined,
  });

  const { selectProps: employeeSelectProps } = useSelect<IEmployee>({
    resource: "employees",
    optionLabel: "name",
    optionValue: "id",
    defaultValue: queryResult?.data?.data.id_employee || undefined, 
  });

  const { selectProps: createdByEmployeeSelectProps } = useSelect<IEmployee>({
    resource: "employees",
    optionLabel: "name",
    optionValue: "id",
    defaultValue: queryResult?.data?.data?.covered_by || undefined, 
  });

  const handleWorkerChange = async(value: any) => {
    setIdEmployee(value);
  }

  const handleCoveredChange = async(value: any) => {
    if(value === 'yes') {
      setIsHiddenCovered(false);
    } else {
      setIsHiddenCovered(true);
    }
  }

  return (
    <Edit
      title="Editar Incapacidades"
      footerButtons={ () => <SaveButton {...saveButtonProps}>Guardar</SaveButton> }
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Fecha de recepción"
          name="inability_date"
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : "",
          })}
        >
          <DatePicker style={{width: '100%'}} format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item
          label="Empleado"
          name="id_employee"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select 
            showSearch 
            {...employeeSelectProps} 
            onChange={handleWorkerChange}
          />
        </Form.Item>
        <Form.Item
          label="Celular"
          name="phone"
          rules={[
            {
              required: true,
            },
          ]}
          shouldUpdate={(prevValues, curValues) =>
            prevValues.phone !== curValues.phone
          }
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Unidad Organizaciónal/Académica"
          name="id_organizational_unit"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select 
            showSearch
            {...uoSelectProps} 
          />
        </Form.Item>
        <Form.Item
          label="Dias de incapacidad"
          name="inability_days"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Folio de incapacidad"
          name="inability_invoice"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="A partir de:"
          name="inability_start_date"
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : "",
          })}
        >
          <DatePicker style={{width: '100%'}} format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item
          label="Expedido el:"
          name="inability_registered_date"
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : "",
          })}
        >
          <DatePicker style={{width: '100%'}} format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item
          label="Dias estimados de recuperación:"
          name="inability_recovery_days"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
            label="¿Cubierto?"
            name="covered"
            rules={[
              {
                required: false,
              },
            ]}
            initialValue={ queryResult?.data?.data.covered_by ? 'yes' : 'no' }
          >
            <Select
              options={[
                {
                  label: "Sí",
                  value: "yes",
                },
                {
                  label: "No",
                  value: "no",
                }
              ]}
              onChange={handleCoveredChange}
            />
        </Form.Item>
        <Form.Item
          label="Empleado que cubre:"
          name="covered_by"
          hidden={isHiddenCovered}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Select 
            showSearch 
            {...createdByEmployeeSelectProps}
          />
        </Form.Item>
        <Form.Item
          label="Días que cubre:"
          name="covered_days"
          hidden={isHiddenCovered}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Observaciones"
          name="observations"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea rows={5} />
        </Form.Item>
        <Form.Item
          label="Quien firma?"
          name="inability_signer"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={[
              {
                label: "Administrativo",
                value: "ADMINISTRATIVE",
              },
              {
                label: "Vicerrector",
                value: "VICERRECTOR",
              }
            ]}
          />
        </Form.Item>
        <Form.Item
            label="Estatus"
            name="status"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Select
              options={[
                {
                  label: "REGISTRADA",
                  value: "REGISTERED",
                },
                {
                  label: "ENVIADA A CUL.",
                  value: "SENT",
                },
                {
                  label: "CAPTURADA EN PRESTACIONES",
                  value: "FINISHED",
                }
              ]}
            />
          </Form.Item>
      </Form>
    </Edit>
  );
};
