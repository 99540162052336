import { IResourceComponentsProps, usePermissions } from "@pankod/refine-core";
import {
  Edit,
  Form,
  Input,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IEmployee, IOrganizationalUnit } from "interfaces";
import { PermissionsConstants } from "constants/permissions";

export const EmployeeEdit: React.FC<IResourceComponentsProps> = () => {

  const { data: permissionsData } = usePermissions();

  const { formProps, saveButtonProps, queryResult } = useForm<IEmployee>();

  const { selectProps: uoSelectProps } = useSelect<IOrganizationalUnit>({
    resource: "organizational-units",
    optionLabel: "name",
    optionValue: "id",
    defaultValue: queryResult?.data?.data?.id_organizational_unit,

  });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        { permissionsData?.includes(PermissionsConstants.EMPLOYEES_GOD) && (
          <Form.Item
            label="No. Empleado"
            name="id"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item
          label="Nombre"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Celular"
          name="phone"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Tipo"
          name="type"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={[
              {
                label: "Administrativo",
                value: "ADMINISTRATIVO",
              },
              {
                label: "Acádemico",
                value: "ACADÉMICO",
              },
              {
                label: "Confianza",
                value: "CONFIANZA",
              },
              {
                label: "Jubilado",
                value: "JUBILADO",
              }
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Unidad Organizaciónal/Académica"
          name="id_organizational_unit"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...uoSelectProps} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
