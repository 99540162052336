import { IResourceComponentsProps, usePermissions, useShow } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
  Tag,
  Modal,
  Show,
  Typography,
  Button,
  CreateButton,
  Menu,
  Dropdown,
  Form,
  Input,
  useModalForm,
  useModal,
  DateField,
} from "@pankod/refine-antd";
import { ICorrespondence, ICorrespondenceResolve } from "interfaces";
import { useState } from "react";
import { CheckOutlined, CloseOutlined, DownOutlined } from "@ant-design/icons";
import { PermissionsConstants } from "constants/permissions";

const getTagValue = (value: string = '') => {
  switch(value) {
    case 'WORK': return 'Trabajo';
    case 'PERSONAL': return 'Personal';
    case 'REGISTERED': return 'Registrado';
    case 'PROCESS': return 'En Proceso';
    case 'REJECTED': return 'Rechazado';
    case 'COMPLETED': return 'Completado';
    default: return value;
  }
}

const getTagColor = (value: string = '') => {
  switch(value) {
    case 'REGISTERED': return 'magenta';
    case 'PROCESS': return 'blue';
    case 'REJECTED': return '#f50';
    case 'COMPLETED': return '#87d068';
  }
  return 'blue';
}

const getResolveTitle = (type: number = 0) => {
  switch(type) {
    case 1: return 'Atender';
    case 2: return 'Atender como Rechazado';
    case 3: return 'Atender como Completado';
  }
  return 'blue';
}

const { Title, Text } = Typography;

export const CorrespondenceList: React.FC<IResourceComponentsProps> = () => {

  const { data: permissionsData } = usePermissions();

  const { tableProps, sorter, tableQueryResult } = useTable<ICorrespondence>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    initialPageSize: 15,
  });
  const { modalProps: showModalProps, show, close } = useModal();
  const { queryResult, showId, setShowId } = useShow<ICorrespondence>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { modalProps: resolveModalProps, formProps: resolveFormProps, show: showResolveModal, queryResult: resolveQueryResult } = useModalForm<ICorrespondenceResolve>({
    action: 'edit',
    resource: 'correspondence/resolve',
    redirect: false,
    autoSubmitClose: true,
    onMutationSuccess: (d) => {
      tableQueryResult.refetch();
    },
  });

  const [resolveType, setResolveType] = useState(1);

  const handleDropdown = (id: any) => {
    setResolveType(1);
    showResolveModal(id);
  }

  const handleMenuClick = (e: any, id: any) => {
    const key = e.key;
    switch(key) {
      case '2':
        setResolveType(2);
        showResolveModal(id);
        break;
      case '3':
        setResolveType(3);
        showResolveModal(id);
        break;
    }
  };

  const DropDownMenu = (id: any) => (
    <Menu
      onClick={(e) => handleMenuClick(e, id)}
      items={[
        {
          label: 'Atender como Rechazado',
          key: '2',
        },
        {
          label: 'Atender como Completado',
          key: '3',
        },
      ]}
    />
  );

  return (
    <>
      <List 
        title="Asuntos Laborales y Correspondencía"
        headerButtons={() => <CreateButton type="primary">Nuevo Asunto</CreateButton> }
      >
        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="id"
            key="id"
            title="Folio"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("id", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="createdAt"
            key="createdAt"
            title="Fecha de Registro"
            render={(value) => <DateField value={value} format="DD/MM/YYYY" />}
            defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="name"
            key="name"
            title="Nombre"
            render={(value, row: ICorrespondence) => {
              return <>
                <TextField value={ value || row?.employee?.name} />
                { row?.id_employee && <Tag color="blue" style={{marginLeft: '10px'}}>Externo</Tag>}
              </>;
            }}
            defaultSortOrder={getDefaultSortOrder("name", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="phone"
            key="phone"
            title="Celular"
            render={(value, row: ICorrespondence) => <TextField value={row?.employee?.phone || value} />}
            defaultSortOrder={getDefaultSortOrder("phone", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="id_organizational_unit"
            key="id_organizational_unit"
            title="UO/UA"
            render={(value, row: ICorrespondence) => <TextField value={ row?.organizational_unit?.name || row?.employee?.organizational_unit?.name || "---"} /> }
            defaultSortOrder={getDefaultSortOrder("id_organizational_unit", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="subject"
            key="subject"
            title="Tipo de asunto"
            render={(value) => <Tag color={getTagColor(value)}>{getTagValue(value)}</Tag>}
            defaultSortOrder={getDefaultSortOrder("subject", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="status"
            key="status"
            title="Estatus"
            render={(value) => <Tag color={getTagColor(value)}>{getTagValue(value)}</Tag>}
            defaultSortOrder={getDefaultSortOrder("status", sorter)}
            sorter
          />
          <Table.Column<ICorrespondence>
            title="Acciones"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                { (permissionsData?.includes(PermissionsConstants.CORRESPONDENCE_GOD) || record.status === 'REGISTERED') && (
                  <EditButton hideText size="small" recordItemId={record.id} />
                )}
                <ShowButton hideText size="small" recordItemId={record.id}  
                  onClick={() => {
                    setShowId(record.id);
                    show();
                }} />
                { (permissionsData?.includes(PermissionsConstants.CORRESPONDENCE_GOD) || record.status === 'REGISTERED') && (
                  <DeleteButton 
                    hideText
                    size="small"
                    recordItemId={record.id}
                    confirmTitle="¿Seguro que deseas eliminar este registro?"
                    confirmOkText="Borrar"
                    confirmCancelText="Cancelar"
                  />
                )}
                { (permissionsData?.includes(PermissionsConstants.CORRESPONDENCE_GOD) && (record.status === 'REGISTERED' || record.status === 'PROCESS')) && (
                  record.status === 'REGISTERED' ?
                    <Dropdown.Button
                      icon={<DownOutlined />}
                      overlay={DropDownMenu(record.id)}
                      type="primary"
                      onClick={() => handleDropdown(record.id)}
                    >
                      Atender
                    </Dropdown.Button>
                    :
                    <>
                      <Button 
                        type="primary"
                        size="small"
                        icon={<CheckOutlined />}
                        onClick={() => handleMenuClick( {key: '3'}, record.id) } 
                      />
                      <Button
                        type="primary"
                        danger
                        size="small"
                        icon={<CloseOutlined />} 
                        onClick={() => handleMenuClick( {key: '2'}, record.id) } 
                      />
                    </>
                )}
              </Space>
            )}
          />
        </Table>
      </List>
      <Modal {...showModalProps} onCancel={close} onOk={close}>
        <Show 
          isLoading={isLoading}
          recordItemId={showId}
          headerProps={{
            title: 'Asunto laboral'
          }}
          headerButtons={<></>}
        >
          <Title level={5}>Folio</Title>
          <Text>{record?.id}</Text>
          <Title level={5}>ID Empleado</Title>
          { record?.id_employee ? <Text>{record?.id_employee}</Text> : <Tag color="blue">Externo</Tag> }
          <Title level={5}>Nombre</Title>
          <Text>{record?.name || record?.employee?.name}</Text>
          <Title level={5}>Clave UO/UA</Title>
          <Text>{record?.employee?.organizational_unit?.id}</Text>
          <Title level={5}>UO/UA</Title>
          <Text>{record?.employee?.organizational_unit?.name}</Text>
          <Title level={5}>Tipo de asunto</Title>
          <Tag color={getTagColor(record?.subject)}>{getTagValue(record?.subject)}</Tag>
          { record?.subject_description && (
            <>
              <Title level={5}>Asunto:</Title>
              <Text>{record?.subject_description}</Text>
            </>
          )}
          <Title level={5}>Estatus</Title>
          <Tag color={getTagColor(record?.status)}>{getTagValue(record?.status)}</Tag>
          { record?.subject_result && (
            <>
              <Title level={5}>Resultado</Title>
              <Text>{record?.subject_result}</Text>
            </>
          )}
            { record?.subject_rejected && (
            <>
              <Title level={5}>Razon del rechazo</Title>
              <Text>{record?.subject_rejected}</Text>
            </>
          )}
        </Show>
      </Modal>
      <Modal {...resolveModalProps} title={getResolveTitle(resolveType)}>
        <Form {...resolveFormProps} layout="vertical">
          <Title level={5}>Folio</Title>
          <Text>{resolveQueryResult?.data?.data?.id}</Text>
          <Title level={5}>ID Empleado</Title>
          { resolveQueryResult?.data?.data?.id_employee ? <Text>{resolveQueryResult?.data?.data?.id_employee}</Text> : <Tag color="blue">Externo</Tag> }
          <Title level={5}>Nombre</Title>
          <Text>{resolveQueryResult?.data?.data?.name || resolveQueryResult?.data?.data?.employee?.name}</Text>
          <Form.Item
            label="Asunto"
            name="subject_description"
            rules={[
              {
                required: resolveType === 1,
              },
            ]}
          >
            <Input.TextArea rows={5} />
          </Form.Item>
          { resolveType === 2 && (
              <Form.Item
              label="Razon del rechazo"
              name="subject_rejected"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.TextArea rows={5} />
            </Form.Item>
          )}
          { resolveType === 3 && (
            <Form.Item
              label="Resultado"
              name="subject_result"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.TextArea rows={5} />
            </Form.Item>
          )}
          <Form.Item
            name="resolve_type"
            hidden={true}
            rules={[
              {
                required: true,
              },
            ]}
            initialValue={resolveType}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
