import React from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Space,
  Button,
  Modal,
  useModal,
  Form,
  Input,
} from "@pankod/refine-antd";
import { useNavigation, usePermissions } from "@pankod/refine-core";
import { PermissionsConstants } from "constants/permissions";

const { Text } = Typography;

export const DashboardPage: React.FC = () => {
  const { list } = useNavigation();
  const { data: permissionsData } = usePermissions();
  console.log("permissions data => ", permissionsData);

  return (
    <Row gutter={[16, 16]}>
        {
          permissionsData?.includes(PermissionsConstants.SHIPMENTS_INDEX) && (
            <Col xs={24} md={12} lg={8}>
              <Card
                title="Envios a Culiacán"
                style={{ height: "150px", borderRadius: "15px" }}
                headStyle={{ textAlign: "center" }}
                bodyStyle={{ textAlign: "center" }}
              >
                <Space align="center" direction="horizontal">
                  <Text><Button type="primary" onClick={ () => list('shipments')}>Entrar</Button></Text>
                </Space>
              </Card>
            </Col>
          )
        }
        {
          permissionsData?.includes(PermissionsConstants.CORRESPONDENCE_INDEX) && (
            <Col xs={24} md={12} lg={8}>
              <Card
                title="Asuntos Laborales y Correspondencía"
                style={{ height: "150px", borderRadius: "15px" }}
                headStyle={{ textAlign: "center" }}
                bodyStyle={{ textAlign: "center" }}
              >
                <Space align="center" direction="horizontal">
                  <Text><Button type="primary" onClick={ () => list('correspondence')}>Entrar</Button></Text>
                </Space>
              </Card>
            </Col>
          )
        }
        {
          permissionsData?.includes(PermissionsConstants.UNION_PROPOSAL_INDEX) && (        
            <Col xs={24} md={12} lg={8}>
              <Card
                title="Propuesta Sindical"
                style={{ height: "150px", borderRadius: "15px" }}
                headStyle={{ textAlign: "center" }}
                bodyStyle={{ textAlign: "center" }}
              >
                <Space align="center" direction="horizontal">
                  <Text><Button type="primary" onClick={ () => list('union-proposals')}>Entrar</Button></Text>
                </Space>
              </Card>
            </Col>
          )
        }        
        {
          permissionsData?.includes(PermissionsConstants.DISABILITIES_INDEX) && (        
            <Col xs={24} md={12} lg={8}>
              <Card
                title="Incapacidades"
                style={{ height: "150px", borderRadius: "15px" }}
                headStyle={{ textAlign: "center" }}
                bodyStyle={{ textAlign: "center" }}
              >
                <Space align="center" direction="horizontal">
                  <Text><Button type="primary" onClick={ () => list('disabilities')}>Entrar</Button></Text>
                </Space>
              </Card>
            </Col>
          )
        }
      </Row>
  );
};