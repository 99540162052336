import { IResourceComponentsProps, useOne, usePermissions } from "@pankod/refine-core";
import {
  Edit,
  Form,
  Input,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { ICorrespondence, IEmployee, IOrganizationalUnit } from "interfaces";
import { useEffect, useState } from "react";
import { PermissionsConstants } from "constants/permissions";

export const CorrespondenceEdit: React.FC<IResourceComponentsProps> = () => {

  const { data: permissionsData } = usePermissions();
  const [isExternal, setIsExternal] = useState(false);
  const [idEmployee, setIdEmployee] = useState(0);

  const { form, formProps, saveButtonProps, queryResult } = useForm<ICorrespondence>({
    queryOptions: {
      onSuccess: ({ data }) => {
        if(!data?.id_employee) {
          setIsExternal(true);
          form.setFieldsValue({
            worker_type: 'yes',
          });
        }
      }
    }
  });

  const employeeQueryResult = useOne<IEmployee>({
    resource: "employees",
    id: idEmployee,
    queryOptions: {
        enabled: false,
        onSuccess: ({ data }) => {
          form.setFieldsValue({
            phone: data?.phone || '',
            id_organizational_unit: data?.id_organizational_unit,
          });
        },
    },
  });

  useEffect(() => {employeeQueryResult.refetch()}, [idEmployee]);

  const { selectProps: employeeSelectProps } = useSelect<IEmployee>({
    resource: "employees",
    optionLabel: "name",
    optionValue: "id",
    defaultValue: queryResult?.data?.data.id_employee || undefined, 
  });


  const { selectProps: uoSelectProps } = useSelect<IOrganizationalUnit>({
    resource: "organizational-units",
    optionLabel: "name",
    optionValue: "id",
    defaultValue: queryResult?.data?.data.id_organizational_unit, 
  });

  const handleWorkerTypeChange = (value: any) => {
    form.setFieldsValue({
      id_employee: undefined,
      name: undefined,
      phone: undefined,
      id_organizational_unit: undefined,
    });
    setIsExternal(value === 'yes' ? true : false);
  }

  const handleWorkerChange = async(value: any) => {
    await setIdEmployee(value);
  }

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      title="Editar Asunto Laboral"
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
            label="¿Trabajador Externo?"
            name="worker_type"
            rules={[
              {
                required: true,
              },
            ]}
            initialValue={'no'}
          >
            <Select
              options={[
                {
                  label: "Sí",
                  value: "yes",
                },
                {
                  label: "No",
                  value: "no",
                }
              ]}
              onChange={handleWorkerTypeChange}
            />
          </Form.Item>
          { isExternal ? (
            <>
              <Form.Item
                label="Nombre Completo"
                name="name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Celular"
                name="phone"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                label="Empleado"
                name="id_employee"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select 
                  showSearch 
                  {...employeeSelectProps} 
                  onChange={handleWorkerChange}
                />
              </Form.Item>
              <Form.Item
                label="Celular"
                name="phone"
                rules={[
                  {
                    required: true,
                  },
                ]}
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.phone !== curValues.phone
                }
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Tipo de asunto"
                name="subject"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  options={[
                    {
                      label: "Personal",
                      value: "PERSONAL",
                    },
                    {
                      label: "Laboral",
                      value: "WORK",
                    }
                  ]}
                />
              </Form.Item>
              <Form.Item
                label="Unidad Organizaciónal/Académica"
                name="id_organizational_unit"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select 
                  showSearch
                  {...uoSelectProps} 
                />
              </Form.Item>
            </>
          )}
          { permissionsData?.includes(PermissionsConstants.CORRESPONDENCE_GOD) && (
            <>
              <Form.Item
                label="Asunto"
                name="subject_description"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input.TextArea rows={5} />
              </Form.Item>
              <Form.Item
                label="Razon del rechazo"
                name="subject_rejected"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input.TextArea rows={5} />
              </Form.Item>
              <Form.Item
                label="Resultado"
                name="subject_result"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input.TextArea rows={5} />
              </Form.Item>
              <Form.Item
                label="Estatus"
                name="status"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Select
                  options={[
                    {
                      label: "Registrado",
                      value: "REGISTERED",
                    },
                    {
                      label: "Procesando",
                      value: "PROCESS",
                    },
                    {
                      label: "Completado",
                      value: "COMPLETED",
                    },
                    {
                      label: "Rechazado",
                      value: "REJECTED",
                    }
                  ]}
                />
              </Form.Item>
            </>
          )}
      </Form>
    </Edit>
  );
};
