import axios from "axios";
import { API_URL, TOKEN_KEY } from "constants/environment";

export const downloadFileFromApi = async(url: string, fileName: string, data: any = {}) => {
  const token = localStorage.getItem(TOKEN_KEY);
  try {
    const response = await  axios({
      url: `${API_URL}${url}`,
      method: 'GET',
      params: data,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
  
    const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = objectUrl;
    link.setAttribute('download', fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
  }catch(e){
    return false;
  }
  return true;
}