import { AuthProvider } from "@pankod/refine-core";
import { AxiosInstance } from "@pankod/refine-simple-rest/node_modules/axios";
import { API_URL, TOKEN_KEY, PERMISSIONS_KEY } from "constants/environment";

export const authProvider = (httpClient: AxiosInstance, setPermissions: any): AuthProvider => {
  return {
    login: async ({ username, password }) => {
      try{
        const { data } = await httpClient.post(`${API_URL}/auth-vice/login`, {
          username,
          password,
        });
        localStorage.setItem(TOKEN_KEY, data.token);
        localStorage.setItem(PERMISSIONS_KEY, data.permissions);
        setPermissions(data.permissions);
        return Promise.resolve();
      } catch (error: any) {
        if( error.response?.status === 401 ) {
          return Promise.reject({
            name: 'Credenciales no validas!',
            message: 'El usuario o contraseña no son validos',
        });
        }
        return Promise.reject(error);
      }
    },
    logout: () => {
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(PERMISSIONS_KEY);
      return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
      const token = localStorage.getItem(TOKEN_KEY);
      if (token) {
        return Promise.resolve();
      }

      return Promise.reject();
    },
    getPermissions: async() => {
      const { data } = await httpClient.get(`${API_URL}/auth/permissions`);
      if(!data) {
        return Promise.reject();
      }
      setPermissions(data.permissions);
      return Promise.resolve(data.permissions.split(","));
    },
    getUserIdentity: async () => {
      const token = localStorage.getItem(TOKEN_KEY);
      if (!token) {
        return Promise.reject();
      }

      return Promise.resolve({
        id: 1,
      });
    }
  };
};
