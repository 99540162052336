import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { Show, Tag, Typography } from "@pankod/refine-antd";

import { IShipment } from "interfaces";

const { Title, Text } = Typography;

export const ShipmentShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IShipment>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>ID</Title>
      <Text>{record?.id}</Text>
      <Title level={5}>Fecha de envio</Title>
      <Text>{record?.shipment_date.toString()}</Text>
      <Title level={5}>Responsable(s)</Title>
      <Text>{record?.responsible}</Text>
      <Title level={5}>Tramites</Title>
      <Text>{record?.formalities}</Text>
      <Title level={5}>Estatus</Title>
      <Tag color="blue">{record?.status}</Tag>
    </Show>
  );
};
