import { CrudFilters, IResourceComponentsProps, useApiUrl, useCreate, useCustom, usePermissions, useShow, useTitle, useUpdate } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
  Tag,
  Modal,
  Show,
  Typography,
  CreateButton,
  Dropdown,
  Menu,
  DateField,
  Button,
  useModalForm,
  Form,
  Input,
  Row,
  Col,
  Icons,
} from "@pankod/refine-antd";
import { IUnionProposal } from "interfaces";
import { useState } from "react";
import { CheckOutlined, CloseOutlined, DownOutlined } from "@ant-design/icons";
import { downloadFileFromApi } from "utils";
import DatePicker from "components/atoms/datepicker.atom";
import dayjs from "dayjs";
import { PermissionsConstants } from "constants/permissions";

const getTagValue = (value: string = '') => {
  switch(value) {
    case 'TEMPORARY': return 'TEMPORAL';
    case 'DEFINITIVE': return 'DEFINITIVO';
    case 'REGISTERED': return 'REGISTRADA';
    case 'TRANSIT': return 'EN TRANSITO';
    case 'REFUSED': return 'RECHAZADA';
    case 'ACCEPTED': return 'ACEPTADA';
    case 'SENT': return 'ENVIADA';
    case 'FINISHED': return 'MOVIMIENTO REALIZADO';
    default: return value;
  }
}

const getTagColor = (value: string = '') => {
  switch(value) {
    case 'FINISHED': return 'green';
    case 'REFUSED': return 'red';
  }
  return 'blue';
}

const { Title, Text } = Typography;

export const UnionProposalList: React.FC<IResourceComponentsProps> = () => {

  const { data: permissionsData } = usePermissions();

  const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<IUnionProposal>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    initialPageSize: 15,
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { q } = params;
      filters.push(
          {
              field: 'search',
              operator: 'eq',
              value: q,
          },
      );
      return filters;
    },
  });
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [visible, setVisible] = useState(false);
  const { queryResult, showId, setShowId } = useShow<IUnionProposal>();

  const { mutate } = useUpdate<IUnionProposal>();

  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { modalProps: resolveModalProps, formProps: resolveFormProps, show: showResolveModal, queryResult: resolveQueryResult } = useModalForm<IUnionProposal>({
    resource: 'union-proposals',
    action: 'edit',
    redirect: false,
    autoSubmitClose: true,
    onMutationSuccess: (d) => {
      tableQueryResult.refetch();
    },
  });

  const handleDropDownF1 = async(id: any) => {
    setIsLoadingFile(true);
    const fileName = `carta_presentacion_${id}.pdf`;
    await downloadFileFromApi(`/union-proposals/files/generate`, fileName, { id, file: 'union-proposal-presentation', type: 'pdf' });
    setIsLoadingFile(false);
  }

  const handleDropDownMenuF1Click = async(e: any, id: any) => {
    setIsLoadingFile(true);
    const key = e.key;
    const fileName = `carta_presentacion_${id}.docx`;
    switch(key) {
      case '2':
        console.log("PDF");
        await downloadFileFromApi(`/union-proposals/files/generate`, fileName, { id, file: 'union-proposal-presentation', type: 'docx' });
        setIsLoadingFile(false);
        break;
    }
  };

  const DropDownMenuF1 = (id: any) => (
    <Menu
      onClick={(e) => handleDropDownMenuF1Click(e, id)}
      items={[
        {
          label: 'Word',
          key: '2',
        },
      ]}
    />
  );

  const handleUpdateStatus = (action:string, id: any) => {
    mutate({
      resource: 'union-proposals',
      id,
      values: {
        status: action
      }
    })
  }

  const handleDropDownF2 = async(id: any) => {
    setIsLoadingFile(true);
    const fileName = `carta_recursos_humanos_${id}.pdf`;
    await downloadFileFromApi(`/union-proposals/files/generate`, fileName, { id, file: 'union-proposal-contract', type: 'pdf' });
    setIsLoadingFile(false);
  }


  const handleDropDownMenuF2Click = async(e: any, id: any) => {
    setIsLoadingFile(true);
    const key = e.key;
    const fileName = `carta_recursos_humanos_${id}.docx`;
    switch(key) {
      case '2':
        console.log("PDF");
        await downloadFileFromApi(`/union-proposals/files/generate`, fileName, { id, file: 'union-proposal-contract', type: 'docx' });
        setIsLoadingFile(false);
        break;
    }
  };

  const DropDownMenuF2 = (id: any) => (
    <Menu
      onClick={(e) => handleDropDownMenuF2Click(e, id)}
      items={[
        {
          label: 'Word',
          key: '2',
        },
      ]}
    />
  );

  const renderActionButton = (record: IUnionProposal) => {
    switch(record.status) {
      case 'REGISTERED':
        return <Button type="primary" color="blue" onClick={() => handleUpdateStatus('TRANSIT', record.id)}>En Transito</Button>;
      case 'TRANSIT':
        return (
          <>
            <Button 
              type="primary"
              size="small"
              icon={<CheckOutlined />}
              onClick={() => handleUpdateStatus('ACCEPTED', record.id) } 
            />
            <Button
              type="primary"
              danger
              size="small"
              icon={<CloseOutlined />} 
              onClick={() => showResolveModal(record.id) } 
            />
          </>
        );
      case 'ACCEPTED':
        return (
          <>
            <Dropdown.Button
              icon={<DownOutlined />}
              overlay={DropDownMenuF2(record.id)}
              type="primary"
              onClick={() => handleDropDownF2(record.id)}
              loading={isLoadingFile}
            >
              Contrato
            </Dropdown.Button>
            <Button type="primary" color="blue" onClick={() => handleUpdateStatus('SENT', record.id)}>Enviado</Button>
          </>
        );
      case 'SENT':
        if(permissionsData?.includes(PermissionsConstants.UNION_PROPOSAL_GOD)) {
          return <Button type="primary" color="blue" onClick={() =>showResolveModal(record.id)}>Movimiento Capturado</Button>;
        }
        return <></>;
    }
  }

  return (
    <>
      <List title="Propuestas Sindicales" headerButtons={() => <CreateButton type="primary">Nueva Propuesta</CreateButton> }>
        <Form layout="vertical" {...searchFormProps}>
          <Row gutter={[20, 0]}>
            <Col span={12}>
              <Form.Item name="q">
                  <Input
                      placeholder="Nombre"
                      prefix={<Icons.SearchOutlined />}
                  />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                  <Button htmlType="submit" type="primary">
                      Buscar
                  </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="id"
            key="id"
            title="ID"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("id", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="id_employee"
            key="id_employee"
            title="Nombre"
            render={(value, row: IUnionProposal) => <TextField value={ row?.employee?.name } />}
            defaultSortOrder={getDefaultSortOrder("id_employee", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="phone"
            key="phone"
            title="Celular"
            render={(value, row: IUnionProposal) => <TextField value={ row?.employee?.phone } />}
            defaultSortOrder={getDefaultSortOrder("phone", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="id_organizational_unit"
            key="id_organizational_unit"
            title="UO/UA Destino"
            render={(value, row: IUnionProposal) => <TextField value={ row?.organizational_unit?.name || "---"} /> }
            defaultSortOrder={getDefaultSortOrder("id_organizational_unit", sorter)}
            sorter
          />
          <Table.Column
            dataIndex={['employee_replace','name']}
            key="employee_replace_name"
            title="En Sustitución de"
            render={(value, row: IUnionProposal) => <TextField value={ row?.employee_replace?.name || "---"} /> }
            defaultSortOrder={getDefaultSortOrder("id_replace_employee", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="status"
            key="status"
            title="Estatus"
            render={(value) => <Tag color={getTagColor(value)}>{getTagValue(value)}</Tag>}
            defaultSortOrder={getDefaultSortOrder("status", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="transit_at"
            key="transit_at"
            title="Fecha Transito"
            render={(value) => value ? <DateField value={value} format="DD/MM/YYYY" /> : <></>}
            defaultSortOrder={getDefaultSortOrder("transit_at", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="sent_at"
            key="sent_at"
            title="Fecha Enviado"
            render={(value) => value ? <DateField value={value} format="DD/MM/YYYY" /> : <></>}
            defaultSortOrder={getDefaultSortOrder("sent_at", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="finished_at"
            key="finished_at"
            title="Fecha Mov. Realizado"
            render={(value) => value ? <DateField value={value} format="DD/MM/YYYY" /> : <></>}
            defaultSortOrder={getDefaultSortOrder("finished_at", sorter)}
            sorter
          />
          <Table.Column<IUnionProposal>
            title="Actions"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                { (permissionsData?.includes(PermissionsConstants.UNION_PROPOSAL_GOD) || record.status === 'REGISTERED' || record.status === 'TRANSIT') && (
                  <EditButton hideText size="small" recordItemId={record.id} />
                )}
                <ShowButton hideText size="small" recordItemId={record.id}
                  onClick={() => {
                    setShowId(record.id);
                    setVisible(true);
                }} />
                { permissionsData?.includes(PermissionsConstants.UNION_PROPOSAL_GOD) && (
                  <DeleteButton hideText size="small" recordItemId={record.id} />
                )}
                { (record.status === 'REGISTERED' || record.status === 'TRANSIT') && (
                  <Dropdown.Button
                    icon={<DownOutlined />}
                    overlay={DropDownMenuF1(record.id)}
                    type="primary"
                    onClick={() => handleDropDownF1(record.id)}
                    loading={isLoadingFile}
                  >
                    Presentación
                  </Dropdown.Button>
                )}
                { renderActionButton(record) }
              </Space>
            )}
          />
        </Table>
      </List>
      <Modal visible={visible} onCancel={() => setVisible(false)}>
        <Show title="Propuesta Sindical" isLoading={isLoading} recordItemId={showId} headerButtons={<></>}>
          <Title level={5}>ID</Title>
          <Text>{record?.id}</Text>
          <Title level={5}>ID Empleado</Title>
          <Text>{record?.id_employee}</Text>
          <Title level={5}>Nombre</Title>
          <Text>{record?.employee?.name}</Text>
          <Title level={5}>UO/UA</Title>
          <Text>{record?.organizational_unit?.name}</Text>
          <Title level={5}>Cubrirá</Title>
          <Tag color={getTagColor(record?.union_proposal_type)}>{getTagValue(record?.union_proposal_type)}</Tag>
          <Title level={5}>Plaza</Title>
          <Text>{record?.administrative_position?.name}</Text>
          <Title level={5}>Sustitución de</Title>
          <Text>{record?.employee_replace?.name}</Text>
          <Title level={5}>Quien se encuentra</Title>
          <Text>{record?.replace_employee_status?.name}</Text>
          <Title level={5}>Estatus</Title>
          <Tag color={getTagColor(record?.status)}>{getTagValue(record?.status)}</Tag>
        </Show>
      </Modal>
      <Modal {...resolveModalProps} title="Razon del rechazo">
        <Form {...resolveFormProps} layout="vertical">
          <Title level={5}>Folio</Title>
          <Text>{resolveQueryResult?.data?.data?.id}</Text>
          <Title level={5}>Nombre</Title>
          <Text>{resolveQueryResult?.data?.data?.employee?.name}</Text>
          {resolveQueryResult?.data?.data?.status === 'TRANSIT' && (
            <Form.Item
              label="Razon del rechazo"
              name="refused_reason"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.TextArea rows={5} />
            </Form.Item>
          )}
          {resolveQueryResult?.data?.data?.status === 'SENT' && (
            <Form.Item
              label="Fecha de Movimiento Realizado"
              name="finished_at"
              rules={[
                {
                  required: true,
                },
              ]}
              getValueProps={(value) => ({
                value: value ? dayjs(value) : "",
              })}
            >
              <DatePicker style={{width: '100%'}} />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};
