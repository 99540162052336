import { CrudFilters, IResourceComponentsProps, useShow } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
  Modal,
  Show,
  Typography,
  Form,
  Icons,
  Col,
  Row,
  Button,
  Input,
} from "@pankod/refine-antd";
import { IOrganizationalUnit } from "interfaces";
import { useState } from "react";

const { Title, Text } = Typography;

export const OrganizationalUnitList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter, searchFormProps } = useTable<IOrganizationalUnit>({
    initialSorter: [
      {
        field: "id",
        order: "asc",
      },
    ],
    initialPageSize: 15,
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { q } = params;
      filters.push(
          {
              field: 'search',
              operator: 'eq',
              value: q,
          },
      );
      return filters;
    },
  });
  const [visible, setVisible] = useState(false);
  const { queryResult, showId, setShowId } = useShow<IOrganizationalUnit>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <>
      <List>
        <Form layout="vertical" {...searchFormProps}>
          <Row gutter={[20, 0]}>
            <Col span={12}>
              <Form.Item name="q">
                  <Input
                      placeholder="Nombre de Escuela o Director"
                      prefix={<Icons.SearchOutlined />}
                  />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                  <Button htmlType="submit" type="primary">
                      Buscar
                  </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="id"
            key="id"
            title="Clave UO"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("id", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="name"
            key="name"
            title="Nombre"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("name", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="responcible"
            key="responcible"
            title="Director"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("responcible", sorter)}
            sorter
          />
          <Table.Column<IOrganizationalUnit>
            title="Actions"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id}  
                  onClick={() => {
                    setShowId(record.id);
                    setVisible(true);
                }} />
                <DeleteButton hideText size="small" recordItemId={record.id} />
              </Space>
            )}
          />
        </Table>
      </List>
      <Modal visible={visible} onCancel={() => setVisible(false)}>
        <Show isLoading={isLoading} recordItemId={showId}>
          <Title level={5}>Clave UO</Title>
          <Text>{record?.id}</Text>
          <Title level={5}>Nombre</Title>
          <Text>{record?.name}</Text>
          <Title level={5}>Director</Title>
          <Text>{record?.responcible}</Text>
        </Show>
      </Modal>
    </>
  );
};
